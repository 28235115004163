import { get, getForClient, HttpResponse } from './request';

import { type Props as PGCardProps } from '../components/PGCard';

export interface PlaygroundDetailListParams {
  lanType: string;
  pageSize: number;
  pageNo: number;
  status: string;
}

export interface SpliceFloorGroundParams {
  lanType: string;
  playgroundType: string;
  count: number;
  status: string;
}

export interface PlaygroundDetailListResContent {
  page: {
    pageSize: number;
    pageNo: number;
    totalCount: number;
  };
  pageContent: PGCardProps[];
}

/**
 * 获取公益操场列表
 * @param params PlaygroundDetailListParams
 * @returns HttpResponse<PlaygroundDetailListResContent>
 */
export const getPlaygroundDetailList = (params: PlaygroundDetailListParams) => {
  return getForClient<PlaygroundDetailListParams, HttpResponse<PlaygroundDetailListResContent>>(
    '/smart/view/charity/r/tCharityService_pageGetPlaygroundDetailDto',
    params,
  );
};

// 儿童操场页面获取操场数据
export const getPlaygroundListData = (params: PlaygroundDetailListParams) => {
  return get<PlaygroundDetailListParams, HttpResponse<PlaygroundDetailListResContent>>(
    '/smart/view/charity/r/tCharityService_pageGetPlaygroundDetailDto',
    params,
  );
};

// 儿童操场页面获取操场最新  拼接地板操场 （修改接口）
export const getSpliceFloorGroundList = (params: SpliceFloorGroundParams) => {
  return get<SpliceFloorGroundParams, HttpResponse<PlaygroundDetailListResContent>>(
    '/smart/view/charity/r/tCharityService_listPlaygroundDetailDto',
    params,
  );
};

export interface StatisticPlaygroundDataParams {
  acType: string;
}
export interface StatisticPlaygroundDataResContent {
  imgSrc: string;
  count: number;
  adCode: string;
  parentAdCode: string;
  longitude: number;
  latitude: number;
  serialNo: string;
}
/**
 * 获取公益操场的统计信息 For PCMap
 * @param params StatisticPlaygroundDataParams
 * @returns HttpResponse<StatisticPlaygroundDataResContent>
 */
export const getStatisticPlaygroundData = (params: StatisticPlaygroundDataParams, path?: string) => {
  const requestPath = path || '/smart/view/charity/r/tCharityService_statisticPlaygroundData';
  return getForClient<StatisticPlaygroundDataParams, HttpResponse<StatisticPlaygroundDataResContent[]>>(
    requestPath,
    params,
  );
};

interface GetPGDetailBySerialNoParams {
  lanType: string;
  serialNo: string;
}

/**
 * 根据 serialNo 获取详情
 * @param params GetPGDetailBySerialNoParams
 * @returns HttpResponse<PGCardProps['pgCardData']>
 */
export const getPGDetailBySerialNo = (params: GetPGDetailBySerialNoParams, path?: string) => {
  const requestPath = path || '/smart/view/charity/r/tCharityService_getBySerialNo';
  return getForClient<GetPGDetailBySerialNoParams, HttpResponse<PGCardProps['pgCardData']>>(requestPath, params);
};

interface GetPlaygroundDataResContent {
  [key: string]: any;
}
/**
 * 获取操场统计相关详情
 */
export const getPlaygroundData = () => {
  return get<any, HttpResponse<GetPlaygroundDataResContent>>(
    '/smart/view/charity/r/tCharityService_getPlaygroundDataDto',
  );
};

export const getClientPlaygroundData = () => {
  return getForClient<any, HttpResponse<GetPlaygroundDataResContent>>(
    '/smart/view/charity/r/tCharityService_getPlaygroundDataDto',
  );
};
