import React, { ElementType, FC, PropsWithChildren, useEffect, useMemo, useState, useCallback } from 'react';
import Popup from 'antd-mobile/es/components/popup';
import { Spin, Modal } from 'antd';

import { Icon } from '../../SvgIcon';
import { getPGDetailBySerialNo } from '../../../api/playgroundApi';
import { type Props as PGCardProps } from '../../PGCard';
import PGModalContentCard from '../PGModalContentCard';
import styles from './WithModal.module.scss';

interface CusModalProp extends PropsWithChildren {
  visible: boolean;
  onClose(): void;
  [key: string]: any;
}

const h5MarkerCls = styles['h5-show-marker'];

const CusModal: FC<CusModalProp> = (props) => {
  const [platformIsH5, setPlatformIsH5] = useState<boolean | undefined>(undefined);

  const { visible, onClose: propOnClose, children, width } = props;

  const initPlatform = useCallback(() => {
    const h5EleArr = document.getElementsByClassName(h5MarkerCls);
    if (h5EleArr.length) {
      const [h5Ele] = h5EleArr;
      const { display } = window.getComputedStyle(h5Ele);
      setPlatformIsH5(display !== 'none');
    } else {
      setPlatformIsH5(false);
    }
  }, []);

  const onClose = () => {
    if (typeof propOnClose === 'function') propOnClose();
  };
  // 为了兼容公益页面使用地图，弹窗组件需要做适配，
  // 通过window.__MEITUAN_GONGYI_REACT_IN_VUE_ENV__ 区分是在公益项目使用。
  const isGongyi = useMemo(() => {
    if (typeof window === 'object' && window.__MEITUAN_GONGYI_REACT_IN_VUE_ENV__) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    initPlatform();
  }, [visible, initPlatform]);
  const maskStyle = { backgroundColor: 'rgba(0, 0, 0, 0.5)' };

  const RenderEle: ElementType = isGongyi || platformIsH5 ? Popup : Modal;
  const renderEleProps = platformIsH5
    ? {
        visible,
        // Popup 配置
        onClose,
        onMaskClick: onClose,
        showCloseButton: true,
        bodyClassName: styles['popup-wrap-box'],
        // bodyClassName: 'rounded-t-lg overflow-hidden ',
      }
    : {
        open: visible,
        width,
        closeIcon: (
          <span className="svg-anticon">
            <Icon name="r-close" width={16} height={16} />
          </span>
        ),
        // Modal 配置
        onCancel: onClose,
        onOk: onClose,
        wrapClassName: styles['modal-wrap'],
        maskStyle,
        zIndex: 20000,
        centered: true,
      };

  return (
    <div>
      {/* start H5 或者 PC 判断标志位 */}
      <div className={h5MarkerCls} />
      {/* end H5 或者 PC 判断标志位 */}

      <RenderEle {...renderEleProps}>{children}</RenderEle>
    </div>
  );
};

interface Props {
  visible: boolean;
  lanType?: string;
  serialNumber?: string;
  width?: number;
  opts?: { markerSourcePath: string; markerInfoPath: string };
  pgModalCardData?: { [key: string]: any };
  onClose?: (state: boolean) => void;
  changeTab?(params: any): void;
}

const PGCardWithModel: FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const {
    visible,
    lanType = 'zh-CN',
    pgModalCardData,
    serialNumber,
    onClose: propOnClose,
    width,
    opts,
    changeTab,
  } = props;
  // 如果是传值的话，直接展示。
  const [pgDetail, setPgDetail] = useState<PGCardProps['pgCardData']>(() => {
    if (visible && pgModalCardData) {
      return pgModalCardData;
    }
    return {};
  });

  const onClose = () => {
    propOnClose?.(false);
  };

  const onFetchData = useCallback(
    async (serialNo: string) => {
      try {
        setLoading(true);
        const { data } = await getPGDetailBySerialNo({ serialNo, lanType }, opts?.markerInfoPath);
        if (data) {
          setPgDetail(data);
        }
      } finally {
        setLoading(false);
      }
    },
    [lanType, opts?.markerInfoPath],
  );

  useEffect(() => {
    // 假如需要展示
    if (visible) {
      if (serialNumber) {
        // 请求数据
        onFetchData(serialNumber);
      } else {
        // 假如传了原始数据，直接使用
        setLoading(false);
      }
    }
  }, [visible, serialNumber, onFetchData]);

  return (
    <div className={styles['modal-card-container']}>
      <CusModal visible={visible} onClose={onClose} width={width}>
        <Spin spinning={loading} wrapperClassName={styles['content-container']}>
          {!loading && <PGModalContentCard pgModalCardData={pgDetail} changeTab={changeTab} />}
        </Spin>
      </CusModal>
    </div>
  );
};

export default PGCardWithModel;
