import React, { FC, useRef, useState } from 'react';
import Tabs from 'antd-mobile/es/components/tabs';
import Swiper, { SwiperRef } from 'antd-mobile/es/components/swiper';
import classNames from 'classnames';
import { Image } from '../../Image';
import { usePGContext } from '../../GongyiProvider/PlaygroundCtxProvider';
import { PlaygroundType, PlaygroundStatus } from '../../PGCard';

import styles from './PGModalCard.module.scss';

const tabItems = [
  { key: 'buildF', title: '建设前' },
  { key: 'buildE', title: '建设后' },
  { key: 'buildED', title: '操场立牌' },
];

export interface Props {
  pgModalCardData: { [key: string]: string };
  changeTab?(params: any): void;
}

const PGModalCard: FC<Props> = (props) => {
  const { pgModalCardData, changeTab } = props;
  const { PGmodalCardLabel, PGModalCardTabs } = usePGContext();
  const { noLable, crTimeLable, chSrcLable, floorLable, mileageLable } = PGmodalCardLabel;

  const { pgType, pgStatus, pgBulidFImg, pgBulidEImg, pgBulidImg, pgImg } = pgModalCardData;

  const swiperRef = useRef<SwiperRef>(null);
  // const defaultData: Array<{ [key: string]: string }> = [];
  // 用户选中哪个tab
  const [activeIndex, setActiveIndex] = useState(() => {
    if (pgType === PlaygroundType.PLAYGROUND && pgStatus === PlaygroundStatus.EXECUTED) {
      return 1;
    }
    if (pgType === PlaygroundType.PLAYGROUND && pgStatus === PlaygroundStatus.RESERVE) {
      return 0;
    }
    if (pgType === PlaygroundType.BICYCLE_PLAYGROUND && pgStatus === PlaygroundStatus.EXECUTED) {
      return pgBulidEImg ? 1 : 0;
    }
    if (pgType === PlaygroundType.BICYCLE_PLAYGROUND && pgStatus === PlaygroundStatus.RESERVE) {
      return 0;
    }
    return 0;
  });
  // const [cardItemData, setCadrItemData] = useState(defaultData);

  // const dataHandler = () => {
  let cardItemData: Array<{ [key: string]: string }> = [];

  if (pgType === PlaygroundType.PLAYGROUND && pgStatus === PlaygroundStatus.EXECUTED) {
    // 公益操场 建成后 默认展示建设后 activeIndexVal=1
    cardItemData = [
      { key: 'buildF', val: pgBulidFImg },
      { key: 'buildE', val: pgImg },
      { key: 'build', val: pgBulidImg },
    ];
    // arrListUsed = arrList;
  } else if (pgType === PlaygroundType.PLAYGROUND && pgStatus === PlaygroundStatus.RESERVE) {
    // 公益操场 筹建中 默认展示筹建中 activeIndexVal = 0
    // activeIndexVal = 0;
    cardItemData = [{ key: 'buildIng', val: pgBulidFImg }];
    // arrListUsed = arrList;
  } else if (pgType === PlaygroundType.BICYCLE_PLAYGROUND && pgStatus === PlaygroundStatus.EXECUTED) {
    // 单车球场 已建成
    cardItemData = [
      { key: 'buildF', val: pgBulidFImg },
      { key: 'buildE', val: pgBulidEImg },
    ].filter((item) => Boolean(item.val));
    // activeIndexVal = pgBulidEImg ? 1 : 0;
    // arrList.forEach((item) => {
    //   if (item.val) {
    //     arrListUsed.push(item);
    //   }
    // });
  } else {
    // 单车操场 筹建中
    cardItemData = [{ key: 'buildIng', val: pgBulidFImg }];
  }
  // setActiveIndex(activeIndexVal);
  // setCadrItemData(arrListUsed);
  // };

  // useEffect(() => {
  //   dataHandler();
  // }, []);

  return (
    <div className={styles['pg-model-card-box']}>
      <div className={styles['card-img-ground']}>
        <Swiper
          direction="horizontal"
          loop
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={(index: any) => {
            setActiveIndex(index);
          }}
        >
          {cardItemData?.map((item, index) => (
            <Swiper.Item key={index}>
              <div className={styles['card-img-box']}>
                <Image src={item.val} width={750} height={562} layout="responsive" objectFit="cover" />
              </div>
            </Swiper.Item>
          ))}
        </Swiper>
        <Tabs
          activeKey={cardItemData[activeIndex]?.key}
          className={classNames(styles['card-img-tab-box'], cardItemData?.length === 1 && styles['card-img-tab-no-bg'])}
          onChange={(key) => {
            const index = tabItems.findIndex((item) => item.key === key);
            setActiveIndex(index);
            swiperRef.current?.swipeTo(index);
            changeTab?.({ play_label: key });
          }}
        >
          {cardItemData?.map((item) => (
            <Tabs.Tab title={PGModalCardTabs[item.key]} key={item.key} />
          ))}
        </Tabs>
      </div>
      <div className={styles['pg-card-content']}>
        <div className={styles['pg-card-header-box']}>
          <div className={styles['pg-name']}>{pgModalCardData.pgName}</div>
          <div className={styles['pg-address']}>{pgModalCardData.pgAddress}</div>
        </div>

        <div className={styles['pg-info-box']}>
          <div className={styles['pg-card-info-item']}>
            <span className={styles['info-item-lable']}>{noLable}</span>
            <span className={styles['info-item-val']}>{pgModalCardData.pgNoStr}</span>
          </div>
          <div className={styles['pg-card-info-item-line']} />
          <div className={styles['pg-card-info-item']}>
            <span className={styles['info-item-lable']}>{crTimeLable}</span>
            <span className={styles['info-item-val']}>{pgModalCardData.pgCreateTime}</span>
          </div>
          <div className={styles['pg-card-info-item-line']} />

          <div className={styles['pg-card-info-item']}>
            <span className={styles['info-item-lable']}>{chSrcLable}</span>
            <span className={styles['info-item-val']}>{pgModalCardData.pgCharitySrc}</span>
          </div>
          <div className={styles['pg-card-info-item-line']} />

          {/* 非单车球场 */}
          {pgModalCardData.pgType && pgModalCardData.pgType === 'PLAYGROUND' && (
            <div className={styles['pg-card-info-item']}>
              <span className={styles['info-item-lable']}>{floorLable}</span>
              <span className={styles['info-item-val']}>{pgModalCardData.pgFloorNum}</span>
            </div>
          )}
          {/* 单车球场 */}
          {pgModalCardData.pgType && pgModalCardData.pgType === 'BICYCLE_PLAYGROUND' && (
            <div className={styles['pg-card-info-item']}>
              <span className={styles['info-item-lable']}>{mileageLable}</span>
              <span className={styles['info-item-val']}>{pgModalCardData.pgMileage}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PGModalCard.displayName = 'PGModalCard';

export default PGModalCard;
