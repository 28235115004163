import React, { useContext, createContext, PropsWithChildren } from 'react';

type SmartConfig = Record<string, string>;
interface ContextProps {
  /* 操场弹框卡片内容 */
  PGmodalCardLabel: SmartConfig;
  /* 操场弹框Tabs */
  PGModalCardTabs: SmartConfig;
}

const PGCardLabel = {
  noLable: '操场编号',
  crTimeLable: '建成时间',
  chSrcLable: '善款来源',
  floorLable: '拼接地板数',
  mileageLable: '低碳里程数',
};
const pgModalCardTabs = {
  buildF: '建设前',
  buildE: '建设后',
  build: '操场立牌',
  buildIng: '筹建中',
};

const PlaygroundContext = createContext<ContextProps>({
  PGmodalCardLabel: PGCardLabel,
  PGModalCardTabs: pgModalCardTabs,
});

interface ProviderProps {
  value: ContextProps;
}

export const PGContextProvider = (props: PropsWithChildren<ProviderProps>) => {
  const { value, children } = props;
  return <PlaygroundContext.Provider value={value}>{children}</PlaygroundContext.Provider>;
};

export const usePGContext = () => {
  const reportContext = useContext(PlaygroundContext);

  return reportContext;
};
