import React, { FC, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Image } from '../Image';

import styles from './PGCard.module.scss';
// import { PGCardLabel } from '../../data/playgroundList';
import { WithModal as PGDetailModal } from '../../components/PGModalCard';

export enum PlaygroundType {
  /* 操场 */
  PLAYGROUND = 'PLAYGROUND',
  /* 单车操场 */
  BICYCLE_PLAYGROUND = 'BICYCLE_PLAYGROUND',
}

export enum PlaygroundStatus {
  /* 筹建中 */
  RESERVE = 'RESERVE',
  /* 已建成 */
  EXECUTED = 'EXECUTED',
}

export interface Props {
  pgCardData: { [key: string]: string };
}

const defaultImg = 'https://p0.meituan.net/smartvenus/eedfc9a60df5913ee0e24107983b366e2453.png';

const PGCard: FC<Props> = (props) => {
  const { pgCardData: propsPgCardData } = props;
  // 兼容后端返回数据中没有图片的问题
  const pgCardData = useMemo(() => {
    const out = {
      ...propsPgCardData,
    };
    if (!out.pgImg) out.pgImg = '';
    return out;
  }, [propsPgCardData]);

  const router = useRouter();
  // todo 抽离到commom里面
  const { locale = 'zh-CN' } = router;
  let PGCardLabelMap: { [key: string]: string } = {};
  if (locale === 'zh-HK') {
    PGCardLabelMap = {
      noLable: '操場編號',
      crTimeLable: '建成時間',
      chSrcLable: '善款來源',
      floorLable: '拼接地板數',
      mileageLable: '低碳裏程',
    };
  } else {
    PGCardLabelMap = {
      noLable: '操场编号',
      crTimeLable: '建成时间',
      chSrcLable: '善款来源',
      floorLable: '拼接地板数',
      mileageLable: '低碳里程',
    };
  }

  // const { noLable, crTimeLable, chSrcLable, floorLable, mileageLable } = PGCardLabelMap;
  const { noLable, crTimeLable } = PGCardLabelMap;

  const [showCardModal, setShowCardModal] = useState(false);

  const clickCardHandle = () => {
    setShowCardModal(true);
  };

  const cardImgUrl = useMemo(() => {
    const { pgType, pgStatus } = pgCardData;
    let cardimg;
    if (pgType === PlaygroundType.PLAYGROUND && pgStatus === PlaygroundStatus.RESERVE) {
      cardimg = pgCardData.pgBulidFImg || '';
    } else if (pgType === PlaygroundType.PLAYGROUND && pgStatus !== PlaygroundStatus.RESERVE) {
      cardimg = pgCardData.pgImg || '';
    } else if (pgType === PlaygroundType.BICYCLE_PLAYGROUND && pgStatus === PlaygroundStatus.EXECUTED) {
      cardimg = pgCardData.pgBulidEImg;
    } else {
      cardimg = pgCardData.pgBulidFImg;
    }
    return cardimg || defaultImg;
  }, [pgCardData]);

  return (
    <div>
      <div className={styles['pg-card-box']} onClick={clickCardHandle}>
        <div className={styles['card-img-box']}>
          <Image src={cardImgUrl} width={750} height={562} layout="responsive" />
          {/* 只有筹建中才展示标签 */}
          {pgCardData.pgStatus === 'RESERVE' && <span className={styles['card-tag']}>{pgCardData.pgStatusName}</span>}
        </div>
        <div className={styles['pg-card-content']}>
          <div className={styles['pg-name']}>{pgCardData.pgName}</div>
          <div className={styles['pg-address']}>{pgCardData.pgAddress}</div>
          <div className={styles['pg-info-box']}>
            <div className={styles['pg-card-info-item']}>
              <span className={styles['info-item-lable']}>{noLable}</span>
              <span className={styles['info-item-val']}>{pgCardData.pgNoStr}</span>
            </div>
            <div className={styles['pg-card-info-item']}>
              <span className={styles['info-item-lable']}>{crTimeLable}</span>
              <span className={styles['info-item-val']}>{pgCardData.pgCreateTime}</span>
            </div>
            {/* <div className={styles['pg-card-info-item']}>
              <span className={styles['info-item-lable']}>{chSrcLable}</span>
              <span className={styles['info-item-val']}>{pgCardData.pgCharitySrc}</span>
            </div> */}

            {/* 非单车球场 */}
            {/* {pgCardData.pgType && pgCardData.pgType === 'PLAYGROUND' && (
              <div className={styles['pg-card-info-item']}>
                <span className={styles['info-item-lable']}>{floorLable}</span>
                <span className={styles['info-item-val']}>{pgCardData.pgFloorNum}</span>
              </div>
            )} */}
            {/* 单车球场 */}
            {/* {pgCardData.pgType && pgCardData.pgType === 'BICYCLE_PLAYGROUND' && (
              <div className={styles['pg-card-info-item']}>
                <span className={styles['info-item-lable']}>{mileageLable}</span>
                <span className={styles['info-item-val']}>{pgCardData.pgMileage}</span>
              </div>
            )} */}
          </div>
        </div>
      </div>

      {showCardModal && (
        <PGDetailModal
          visible={showCardModal}
          pgModalCardData={pgCardData}
          onClose={() => setShowCardModal(false)}
          width={760}
        />
      )}
    </div>
  );
};

PGCard.displayName = 'PGCard';

export default PGCard;
